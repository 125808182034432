/* * VARIABLES * */
/* can assign variables to use later on.
  colors for the pages and font stacks, etc
*/
:root{
  --primary: #022534;
  --secondary: #e4f5ff;
  --accent1: #96c7d5;
  --accent2: #2d7a9c;
  --accent3: #094f6e;
  --white: white;
  --black: black;
  /* need to change to montserrat for text. Same as logo small text
    argent thin is the TM in tmillsdev
  */
  /* --mono: "Oxygen mono", monospace; */
  --sans: "montserrat", sans-serif;
}

/* border box model css-tricks.com/box-sizing/ 
  html has a higher specificity than *
*/
html {
  box-sizing: border-box;
/* smooth scrolling with a tag onClick */
  scroll-behavior: smooth; 
}
*,
*::before,
*::after {
  box-sizing: inherit;
}


/* * RESET BROWSER CSS and BASE STYLES * */
/* this resets the inherited styles from the browser */
body {
  margin: 0;
  padding: 0;
  background: var(--primary);
  font-family: var(--sans);
  color: var(--white)
}


/* ----------------Landing------------------ */

.landing-parent-div {
  display: grid;
  place-content: center;
  width: auto;
}

.logo-div {
  margin: 0 auto;
}

.landing-logo {
  width: 100%;
  height: auto;
}

/* ----------------Navbar------------------ */

nav {
  font-family: var(--sans);
  font-size: 95%;
  list-style-type: none;
  padding: 1rem;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

nav li:first-child {
  flex-basis: 100%;
  text-align: center;
}

a {
  color: var(--white);
  text-decoration: none;
  display: block;
}


a:hover {
  color: var(--accent1);
  text-decoration: underline;
}

.logo {
  height: 70px;

}

.button {
  background-color: var(--accent3);
  padding: 0.5rem;
  border-radius: 5px;
}

.button:hover {
  color: var(--white);
  background-color: var(--accent2);
}

/* * Responsive * */
@media (min-width: 850px) {
  nav {
    max-width: 1200px;
    margin: 0 auto;
  }

  nav li:first-child {
    flex-basis: auto;
    text-align: left;
    margin-right: auto;
  }

  /* ARROW */

  .arrow,
  .arrow:before {
    position: absolute;
    left: 30%;
  }

  .arrow {
    width: 40px;
    height: 40px;
    top: 80%;
    margin: -20px 0 0 -20px;
    -webkit-transform: rotate(45deg);
    border-left: none;
    border-top: none;
    border-right: 2px var(--white) solid;
    border-bottom: 2px var(--white) solid;
  }
  
  .arrow:before {
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    margin: -16px 0 0 -10px;
    border-left: none;
    border-top: none;
    border-right: 1px #fff solid;
    border-bottom: 1px #fff solid;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: arrow;
  }

  @keyframes arrow {
    0% {
      opacity: 1
    }
    100% {
      opacity: 0;
      transform: translate(-10px, -10px)
    }
  }
}


/* ----------------Home------------------ */

#intro {
  padding: 4rem 1rem 10rem 1rem;
  max-width: 1200px;
  margin: auto;
}

#intro p {
  font-size: 1rem;  
}

#intro .name {
  font-family: var(--mono);
}

.name span {
  font-family: var(--sans);
  font-size: 4rem;
  color: var(--accent2);
  display: block;
}

#intro h2 {
  font-size: 3rem;
  font-family: var(--mono);
}

#intro article img {
  width: 100%;
  height: auto;
}


/* projects section home*/
.projects-section {
  background-color: var(--secondary);
}

/* contact section home */
.contact-section {
  background-color: var(--accent2);
}



/* ----------------Projects------------------ */
article {
  padding: 10px;
  margin:10px;
}

#projects, #intro {
  padding: 4rem 1rem;
}

#projects h2 {
  font-size: 2.5rem;
  margin-bottom: calc(2rem * 1.5);
  color: var(--primary);
}

#projects h3 {
  color: var(--primary);
}

#projects h4 {
  font-size: 1rem;
  font-family: var(--mono);
  margin: 0;
  color: var(--primary)
}

.textbox {
  background-color: var(--primary);
  padding: 1rem;
  border-radius: 10px;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}


#projects ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1rem;
  color: var(--primary);
}

.project-links {
  color: var(--black);
  text-decoration: underline;
}

#projects img {
  padding: 2rem 0 4rem 0;
  border-left: 1px solid var(--primary);
  border-top: 1px solid var(--primary);
  border-radius: 25px;
  padding: 1rem;
  width: 100%;
  height: auto;
}

/* * Responsive * */

@media (min-width: 550px) {
  article {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 1rem;
  }

  #projects, #about, #intro {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
  }

  .intro-text {
    grid-column: 1/7;
    text-align: left;
  }

  .intro-text p {
    padding-top: 10px;
  }

  #intro img {
    grid-column: 7/10;
    grid-row: 1/2;
    margin: 5px;
    height: 80%;
    border-radius: 40%;
  }


  #projects img {
    grid-column: 1/6;
    grid-row: 1/2;
    margin: 5px;
  }
  .project-text, .about-text {
    grid-column: 6/11;
    grid-row: 1/2;
    order: 2;
    text-align: right;
  }

  #projects ul {
    justify-content: flex-end;
    margin: 5px;
  }

  #projects .reverse .project-text,
  #about .reverse .project-text {
    grid-column: 1/6;
    order: 2;
    text-align: left;
  }

  #projects .reverse img,
  #about .reverse img {
    grid-column: 6/11;
    grid-row: 1/2;
  }

  #projects .reverse ul {
    justify-content: flex-start;
  }
}

#projects article a{
  padding: 0.5rem;
}

/* ----------------About-------------------- */

#about {
  padding: 4rem 1rem;
}

#about .textbox {
  border: solid;
  border-color: var(--primary);
  background-color: var(--secondary);
}

#about p {
  color: var(--primary)
}

#about .skills {
  list-style-type: none;
  grid-column: 1/5;
  }

#about .skills ul, .skills-reverse ul {
  list-style-type: none;
  padding: 40px 10px 10px 10px;
  margin: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

#about .skills-reverse {
  list-style-type: none;
  grid-column: 7/11;
  /* grid-row: 1/2; */
}

#about .skills h2, .skills-reverse h2 {
  text-align: center;
  text-decoration: underline;
}



/* ----------------Contact------------------ */

/* section on home */
#contact {
  width: 400px;
  text-align: center;
  margin: 0 auto;
  padding: 3rem 0;
}

/* contact page */
#contact-pane-left {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#contact-pane-left img {
  width: 50%;
  height: auto;
  border-radius: 50%;
  display: flex;
}

.contact-pane-left-text {
  font-size: 80px;
  color: var(--secondary);
}

#contact-pane-right {
  background-color: var(--accent2);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact {
  padding: 10px;
}

button {
  overflow: visible;
}

button {
  text-transform: none;
}

button, input, select, textarea {
  color: var(--secondary);
  font: inherit;
  margin: 0;
}

input {
  line-height: normal;
}

textarea {
  overflow: auto;
}

#container {
  border: solid 3px var(--secondary);
  margin: 0 auto;
  max-width: 650px;
  max-height: 650px;
  position: relative;
  width: 100%;
}

form {
  padding: 10px;
}

h5 {
  color: var(--secondary);
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 7px;
  text-align: center;
}

.email, .phone {
	float: right;
	width: 45%;
}

input[type='text'], [type='email'], select, textarea {
	background: none;
  border: none;
	border-bottom: solid 2px var(--secondary);
	color: var(--secondary);
	font-size: 1.000em;
  font-weight: 400;
  letter-spacing: 1px;
	margin: 0em 0 1.875em 0;
	padding: 0 0 0.875em 0;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

input[type='text']:focus, [type='email']:focus, textarea:focus {
	outline: none;
	padding: 0 0 0.875em 0;
}

.message {
	float: none;
}

.form-name, .company {
	float: left;
	width: 45%;
}


textarea {
	line-height: 150%;
	height: 150px;
	resize: none;
  width: 100%;
}

::-webkit-input-placeholder {
	color: var(--secondary);
}

#form_button {
  background: none;
  border: solid 2px var(--secondary);
  color: var(--secondary);
  cursor: pointer;
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  padding: 20px 35px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}


#form_button:hover {
  background: var(--secondary);
  color: var(--primary);
}


/* * Responsive * */

@media (max-width: 800px) {
  .splitscreen-container {
    flex-direction: column;
  }
}

/* ----------------RESUME------------------- */
/* centering the pdf  */
.react-pdf__Page__canvas {
  margin: 0 auto; 
}


/* ----------------FOOTER------------------- */

footer {
  text-align: center;
}

footer img {
  margin-top: 1rem;
}

footer ul {
  list-style-type: none;
  padding: 0rem;
  margin: 2rem 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 3rem;
  font-size: 1.5rem;
}



